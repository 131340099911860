import * as React from "react";
import { Link } from "gatsby";

import { skipNav, main } from "./skip-nav.module.scss";

const defaultId = `skip-to-content`;

export function SkipNavLink({
  children = `Skip to content`,
  contentId,
  ...props
}) {
  const id = contentId || defaultId;

  return (
    <Link {...props} to={`#${id}`} data-skip-to-content className={skipNav}>
      {children}
    </Link>
  );
}

/**
 * Wrap the main content of a page with this, thus also the <main> tag
 */
export function SkipNavContent({ children, id: idProp, ...props }) {
  const id = idProp || defaultId;

  return (
    <main {...props} id={id} className={main}>
      {children}
    </main>
  );
}
