import React, { useEffect } from "react";
import gsap, { Power2 } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import { animator, flare } from "./animator.module.scss";

export const Animator = () => {
  gsap.registerPlugin(MotionPathPlugin);

  useEffect(() => {
    gsap.to("#flare", {
      duration: 10,
      repeat: -1,
      yoyo: true,
      ease: Power2.easeInOut,
      motionPath: {
        path: "#path",
        align: "#path",
        alignOrigin: [0.5, 0.5],
        autoRotate: 90,
        start: 1,
        end: 0,
      },
    });
  });

  return (
    <div id="animator" className={`animator ${animator}`}>
      <div id="flare" className={flare} />
      <svg preserveAspectRatio="none" viewBox="0 0 100 100">
        <path
          id="path"
          fill="none"
          strokeWidth="2"
          d="M99,1 C95,95 5,5 1,99"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </div>
  );
};
