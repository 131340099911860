import React from "react";

import trajanLogo from "../images/trajan-logo.png";

export const Logo = ({ theme, width }) => {
  // const pathColor = theme === "light" ? "#FFF" : "#003B4A";

  const imgWidth = width || "auto";

  return <img alt="Logo Trajan" src={trajanLogo} style={{ width: imgWidth }} />;
};
