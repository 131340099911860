import React from "react";
import { Container } from "react-bootstrap";

import { Layout } from "../components/layout";
import { Section } from "../components/section";

// markup
const IndexPage = () => {
  return (
    <div>
      <Layout className="home" current="/">
        <Container></Container>
      </Layout>
    </div>
  );
};

export default IndexPage;
