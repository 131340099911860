import React from "react";

import { layout } from "./layout.module.scss";

import { SkipNavContent, SkipNavLink } from "./skip-nav";
import { Header } from "./header";
// import { Overlay } from "./overlay";
import { Footer } from "./footer";
import { Seo } from "./seo";

export function Layout({ className, children, current, seo }) {
  React.useEffect(() => {
    window.axeptioSettings = {
      clientId: "6319ec68429025f89b15e7c9",
      cookiesVersion: "trajan-capital-fr",
    };

    if (!document.getElementById("axeptio")) {
      (function (d, s) {
        var t = d.getElementsByTagName(s)[0],
          e = d.createElement(s);
        e.async = true;
        e.id = "axeptio";
        e.src = "//static.axept.io/sdk-slim.js";
        t.parentNode.insertBefore(e, t);
      })(document, "script");
    }
  });

  return (
    <div className={`${layout} ${className}`}>
      <Seo
        title={seo?.title}
        description={seo?.metaDesc}
        isHome={current === "/"}
      />
      <SkipNavLink />
      <Header current={current} />
      <SkipNavContent>{children}</SkipNavContent>
      <Footer />
    </div>
  );
}

Layout.defaultProps = {
  comingSoonEnabled: false,
};
