import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import { Animator } from "./animator";
import { Linkedin } from "./linkedin";

import poincon from "../images/trajan-poincon-green.png";

import {
  footer,
  footerCol,
  infoCol,
  address,
  linkedin,
  subfooter,
  poinconImg,
} from "./footer.module.scss";

export const Footer = (props) => {
  return (
    <div>
      <footer className={footer}>
        <Container>
          <Row>
            <Col className={`${footerCol} ${infoCol}`} xs={10} md={6}>
              <div>
                <img src={poincon} alt="" className={poinconImg} />
              </div>
              <div className={address}>
                <span>Trajan</span>
                <span>48 rue de la Bienfaisance, 75008 Paris</span>
              </div>
            </Col>
            <Col className={`${footerCol} text-right`} xs={2} md={6}>
              <a
                href={
                  "https://www.linkedin.com/company/trajancapital/?viewAsMember=true"
                }
                target="_blank"
                aria-label="Voir la page Linkedin"
                className={linkedin}
              >
                <Linkedin theme={"dark"} />
              </a>
            </Col>
          </Row>
          <Row className={subfooter}>
            <Col className="text-center">
              <Link to="/mentions-legales">Mentions Légales</Link>
            </Col>
          </Row>
        </Container>
      </footer>
      <Animator />
    </div>
  );
};
