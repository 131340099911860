import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

import { Logo } from "./logo";

import * as styles from "./header.module.scss";

const dataMenu = [
  {
    label: "Qui sommes-nous ?",
    url: "/qui-sommes-nous",
    sub_title: null,
    sub_menu: null,
  },
  {
    label: "Nos Entrepreneurs",
    url: "/nos-entrepreneurs",
    sub_title: null,
    sub_menu: null,
  },
  {
    label: "Équipe",
    url: "/equipe",
    sub_title: null,
    sub_menu: null,
  },
  {
    label: "Responsabilité",
    url: "/responsabilite",
    sub_title: null,
    sub_menu: null,
  },
  {
    label: "Journal",
    url: "/journal",
    sub_title: null,
    sub_menu: null,
  },
  {
    label: "Contact",
    url: "/contact",
    sub_title: null,
    sub_menu: null,
  },
];

export const Header = (props) => {
  return (
    <header className={styles.header}>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/" className={styles.brand}>
            <Logo width="120" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <svg width="60" height="60" viewBox="0 0 100 100">
              <path
                className="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path className="line line2" d="M 20,50 H 80" />
              <path
                className="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={`me-auto ${styles.nav}`} activeKey={props.current}>
              {dataMenu.map((i) => (
                <Nav.Link
                  href={i.url}
                  key={i.url}
                  className={`${styles.navLink}`}
                  aria-label={i.url}
                >
                  {i.label}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
