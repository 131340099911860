import React from "react";

import * as styles from "./headline.module.scss";

export const Headline = (props) => {
  const content = props.content || [];

  return (
    <div className={`${styles.headline} ${props.className}`}>
      {props.showNumber && (
        <div className={"number"}>
          <span>{props.number}</span>
        </div>
      )}
      {props.isH1 && (
        <h1>
          {content.map((c, index) => (
            <div key={index} className={`headline__line line${index}`}>
              <span>{c}</span>
            </div>
          ))}
        </h1>
      )}
      {!props.isH1 && (
        <div>
          {content.map((c, index) => (
            <div key={index} className={`headline__line line${index}`}>
              <span>{c}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
